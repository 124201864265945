import React from "react";
import classNames from "classnames";
import RecordThumbnail from "./RecordThumbnail";
import RecordLink from "./RecordLink";
import unescapeXMLCharacters from "src/utils/unescapeXMLCharacters";
import dnzNil from "src/utils/dnzNil";
import formatDate from "src/utils/formatDate";
import { compact, uniq } from "lodash";

const HorizontalRecordCard = ({ editOverlay, children, ...props }) => {
  const {
    id,
    title,
    creator,
    displayDate,
    displayCollection,
    displayContentPartner,
    description,
    link,
    externalLink,
    className
  } = props;

  let storyCreator;

  if (creator) {
    let filtered_creator = creator.filter((creator) => !dnzNil(creator)).join(", ");
    storyCreator = `Created by ${filtered_creator}`;
  }

  return (
    <div
      className={classNames(
        "record-card record-card--horizontal grid-x",
        className
      )}
      id={`card_${id}`}
    >
      {editOverlay}
      <div className="cell small-4 medium-3">
        <RecordThumbnail {...props} />
      </div>
      <div className="record-card__body cell small-8 medium-9">
        <div className="record-card__metadata">
          <p className="record-card__subtitle text-truncate-2">
            {uniq(
              compact([
                formatDate(displayDate),
                displayContentPartner,
                displayCollection,
                storyCreator,
              ])
            ).join(" | ")}
          </p>
          <RecordLink
            className="record-card__link"
            isExternalLink={externalLink}
            link={link}
          >
            <h2 className="record-card__title text-truncate-2">
              {unescapeXMLCharacters(title)}
            </h2>
          </RecordLink>
          {description && (
            <p className="record-card__description text-truncate-3">
              {description}
            </p>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default HorizontalRecordCard;
